import { useTranslation } from "react-i18next";
import ScrollAnimation from 'react-animate-on-scroll';
import TeamMember from "../cells/TeamMember";
import annemiek from '../../Assets/Images/team.webp';
import profile from '../../Assets/Images/profile.webp';
import keltjens from '../../Assets/Images/team3.webp';
import vivian from '../../Assets/Images/team4.webp';
import phaedra from '../../Assets/Images/team5.webp';
import marineke from '../../Assets/Images/team6.webp';

const TeamSection = () => {
    const { t } = useTranslation();
    return (
        <ScrollAnimation animateIn="slideInUp" offset={100} animateOnce>
            <div className="teamSection">
                <p className="h5 teamSectionTitle"> 
                    {t('aboutTitle')}
                </p>
                <span className="">
                    {t('aboutText')}
                </span>
                <div className="teamGrid">
                <ScrollAnimation animateIn="slideInUp" offset={100} animateOnce >
                    <TeamMember image={profile} name={'S. (Samir) Sikiric'} function={`${t('dentist')}`} description={t('')}/>
                </ScrollAnimation>   
                <ScrollAnimation animateIn="slideInUp" offset={100} animateOnce>
                    <TeamMember image={keltjens} name={'L. (Luc) Keltjens'} function={`${t('dentist')} (BIG: 49915125302)`} description={t('keltjensText')}/>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" offset={100} animateOnce>
                    <TeamMember image={marineke} name={'Marineke van Eckeren'} function={t('assistant')} description={t('marinekeText')}/>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" offset={100} animateOnce>
                    <TeamMember image={phaedra} name={'Phaedra Lotz'} function={t('assistant')} description={t('phaedraText')}/>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" offset={100} animateOnce>
                    <TeamMember image={vivian} name={'Vivian Lotz'} function={t('assistant')} description={t('vivianText')}/>
                </ScrollAnimation>
                </div>
            </div>
        </ScrollAnimation>
    )
}

export default TeamSection;